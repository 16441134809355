/*
 * Copyright 2023 VMware, Inc.
 * All rights reserved.
 */

import { GenericObject } from '@dpa/ui-common';
import { createSelector, MemoizedSelector } from '@ngrx/store';

import { MerlotState } from '@dpa-shared-merlot/store/merlot.store';
import {
  DashboardSelectors,
  DashboardTrendDefinitionOverridesSelectors,
  groupCategoriesByIntegration,
  IntegrationMetaSelectors,
  TemplateCommonSelectors,
} from '@ws1c/intelligence-core';
import {
  Category,
  QueryBuilder,
  ReportTemplate,
  StandardWidgetSubtype,
  Template,
  TEMPLATE_TYPE,
  TrendDefinition,
  WidgetDataRequest,
} from '@ws1c/intelligence-models';
import { DataExplorerState } from './data-explorer.state';

/**
 * DataExplorerSelectors
 * @export
 * @class DataExplorerSelectors
 */
export class DataExplorerSelectors {
  /**
   * getDataAccessPolicyState
   * @static
   * @param {MerlotState} state
   * @returns {DataExplorerState}
   * @memberof DataExplorerSelectors
   */
  public static selectDataExplorerState = (state: MerlotState): DataExplorerState => state.dataExplorerState;

  /**
   * previewColumns
   * @static
   * @type {MemoizedSelector<MerlotState, string[]>}
   * @memberof DataExplorerSelectors
   */
  public static previewColumns: MemoizedSelector<MerlotState, string[]> = createSelector(
    DataExplorerSelectors.selectDataExplorerState,
    (state: DataExplorerState) => state.previewColumns,
  );

  /**
   * customQuery
   * @static
   * @type {MemoizedSelector<MerlotState, QueryBuilder>}
   * @memberof DataExplorerSelectors
   */
  public static customQuery: MemoizedSelector<MerlotState, QueryBuilder> = createSelector(
    DataExplorerSelectors.selectDataExplorerState,
    (state: DataExplorerState) => state.customQuery,
  );

  /**
   * previewQuery
   * @static
   * @type {MemoizedSelector<MerlotState, QueryBuilder>}
   * @memberof DataExplorerSelectors
   */
  public static previewQuery: MemoizedSelector<MerlotState, QueryBuilder> = createSelector(
    DataExplorerSelectors.selectDataExplorerState,
    (state: DataExplorerState) => state.previewQuery,
  );

  /**
   * isQueryInitiated
   * @static
   * @type {MemoizedSelector<MerlotState, isQueryInitiated>}
   * @memberof DataExplorerSelectors
   */
  public static isQueryInitiated: MemoizedSelector<MerlotState, boolean> = createSelector(
    DataExplorerSelectors.previewQuery,
    (queryBuilder: QueryBuilder) => !!queryBuilder,
  );

  /**
   * isValidWidget
   * @static
   * @type {MemoizedSelector<MerlotState, boolean>}
   * @memberof DataExplorerSelectors
   */
  public static isValidWidget: MemoizedSelector<MerlotState, boolean> = createSelector(
    DashboardSelectors.getWidgetDataPreviewState,
    (widgetDataPreview: WidgetDataRequest) => !!widgetDataPreview?.success,
  );

  /**
   * showAddReportModal
   * @static
   * @type {MemoizedSelector<MerlotState, boolean>}
   * @memberof DataExplorerSelectors
   */
  public static showAddReportModal: MemoizedSelector<MerlotState, boolean> = createSelector(
    DataExplorerSelectors.selectDataExplorerState,
    (state: DataExplorerState) => state.showAddReportModal,
  );

  /**
   * isSelectDataSourceVisible
   * @static
   * @type {MemoizedSelector<MerlotState, boolean>}
   * @memberof DataExplorerSelectors
   */
  public static isSelectDataSourceVisible: MemoizedSelector<MerlotState, boolean> = createSelector(
    DataExplorerSelectors.selectDataExplorerState,
    (state: DataExplorerState) => state.isSelectDataSourceVisible,
  );

  /**
   * selectedTemplate
   * @static
   * @type {MemoizedSelector<MerlotState, ReportTemplate>}
   * @memberof DataExplorerSelectors
   */
  public static selectedTemplate: MemoizedSelector<MerlotState, ReportTemplate> = createSelector(
    DataExplorerSelectors.selectDataExplorerState,
    (state: DataExplorerState) => state.selectedTemplate,
  );

  /**
   * filterConditionNestedRules
   * @static
   * @type {MemoizedSelector<MerlotState, GenericObject>}
   * @memberof DataExplorerSelectors
   */
  public static dataExplorerWidget: MemoizedSelector<MerlotState, TrendDefinition> = createSelector(
    DashboardTrendDefinitionOverridesSelectors.getTrendDefinitionsByStandardWidgetSubtype,
    (trendDefinitionMap: GenericObject) => trendDefinitionMap?.[StandardWidgetSubtype.DEFAULT_WIDGET],
  );

  /**
   * filterConditionNestedRules
   * @static
   * @type {MemoizedSelector<MerlotState, GenericObject>}
   * @memberof DataExplorerSelectors
   */
  public static filterConditionNestedRules: MemoizedSelector<MerlotState, GenericObject> = createSelector(
    DataExplorerSelectors.dataExplorerWidget,
    (trendDefintion: TrendDefinition) => trendDefintion?.filter_condition_nested_rules,
  );

  /**
   * mainWidgetFilterCondition
   * @static
   * @type {MemoizedSelector<MerlotState, GenericObject>}
   * @memberof DataExplorerSelectors
   */
  public static mainWidgetFilterCondition: MemoizedSelector<MerlotState, GenericObject> = createSelector(
    DashboardSelectors.getWidgetDataPreviewState,
    (widgetData: WidgetDataRequest) => widgetData?.result?.trendDefinition?.filter_condition_nested_rules,
  );

  /**
   * getTemplates
   * @static
   * @type {MemoizedSelector<MerlotState, ReportTemplate[]>}
   * @memberof DataExplorerSelectors
   */
  public static getTemplates: MemoizedSelector<MerlotState, ReportTemplate[]> = createSelector(
    TemplateCommonSelectors.getVisibleTemplates(TEMPLATE_TYPE.REPORTS),
    (templates: Template[]) => {
      return (templates ?? [])
        .map((template: Template) => new ReportTemplate({ ...template }))
        .filter((template: ReportTemplate) => !template.isPredefinedReportTemplate);
    },
  );

  /**
   * getAvailableCategories
   * @static
   * @type {MemoizedSelector<CoreAppState, IntegrationCategories[]>}
   * @memberof IntegrationMetaSelectors
   */
  public static getAvailableCategories = createSelector(IntegrationMetaSelectors.getAvailableCategoriesState, (categories: Category[]) => {
    return groupCategoriesByIntegration(
      categories.filter((category: Category) => category.supportsReporting || category.supportsDashboards),
    );
  });
}
